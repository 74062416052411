<template>
  <div class="container">
       <div class="content">
        <router-view></router-view>
            <!-- <games-select-list v-if="currentSelectGameItem===null" @getCurrentSelectGameItem="getCurrentSelectGameItem"></games-select-list> -->
            <!-- <games-filter-list v-if="currentSelectGameItem!==null" :currentSelectGameItem="currentSelectGameItem"></games-filter-list> -->
            <!-- <games-detail v-if="currentSelectGameItem!==null"></games-detail> -->
       </div>
       <content-footer></content-footer> 
  </div>
</template>

<script>
import Header from '../home/header.vue';
import GamesSelectList from './components/games-select-list.vue';
import GamesFilterList from './components/games-filter-list.vue';
import GamesDetail from './components/games-account-detail.vue';
import ContentFooter from '../home/component/content-footer.vue';
export default {
    name:'BuyList',
    props:{
        gameItem:{
            type:[Object,null],
            default:null
        }
    },
    components:{
        Header,
        GamesSelectList,
        GamesFilterList,
        GamesDetail,
        ContentFooter
    },
    data(){
        return{
            //currentSelectGameItem:null //当前选择的游戏
        }
    },
    methods:{
        //拿到当前选择的游戏
       // getCurrentSelectGameItem(gameItem){
            // if(!this.$store.state.user.userToken){
            //     this.$message.warning('您好，请先登录！');
            //     return;
            // }
        //     this.currentSelectGameItem = gameItem;
        // }
    },
    // mounted(){
    //     this.currentSelectGameItem = this.gameItem;
    // }
}
</script>

<style lang="less" scoped>
.container{
    background:#F5F5F5;
    width: calc(100vw);
    min-width: 1440px;
    .content{
        margin: 0 auto;
        width: 1200px;
    }
}

</style>