<template>
  <div class="games-select-list">
    <div class="games-select-main-menu">
        <ul>
            <li @click="clickMainMenuHndler(0)">
                <p class="games-category-menu-title" :style="crruentMainMenuIndex===0?mainMenuStyle:''">全部</p>
            </li>
            <li @click="clickMainMenuHndler(1)">
                <p class="games-category-menu-title" :style="crruentMainMenuIndex===1?mainMenuStyle:''">手游</p>
            </li>
            <li @click="clickMainMenuHndler(2)">
                <p class="games-category-menu-title" :style="crruentMainMenuIndex===2?mainMenuStyle:''">端游</p>
            </li>
            <div class="games-category-menu-slider" :style="{left:sliderStyle}"></div>
        </ul>
    </div>
    <div class="games-select-sub-menu-index">
        <ul>
            <li v-for="(item,index) in charIndexArray" :key="index" @click="clickSubMenuHandler(index,item)" :style="{background:crruentSubMenuIndex===index?'#FFF3F4':''}">
                <p class="sub-menu-index" :style="crruentSubMenuIndex===index?subMenuStyle:''">{{item}}</p>
            </li>
        </ul>
    </div>
    <div v-if="tempGamesIconList.length>0" class="games-select-items">
        <ul>
            <li v-for="item in tempGamesIconList" :key="item.id" @click="clickGameItemHandler(item)">
                <img class="game-icon" :src="item.gameIcon" alt="游戏图标">
                <p class="game-name">{{item.gameName}}</p>
            </li>
        </ul>
    </div>
    <no-product-page v-else :boxHeight="370" :imageStyle="150" :tipsMarginTop="20"></no-product-page>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import NoProductPage from '../../components/no-product-page.vue';
export default {
    name:'GamesSelectListComponent',
    components:{
        NoProductPage
    },
    data(){
        return{
            crruentMainMenuIndex:0,
            crruentSubMenuIndex:0,
            charIndexArray:["全部游戏","A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
            tempGamesIconList:[]
        }
    },
    methods:{
        //点击当前分类菜单操作
        clickMainMenuHndler(index){
            this.crruentMainMenuIndex = index;
            this.crruentSubMenuIndex = 0;
            //筛选游戏主分类
            this.selectMainGames(index);
        },
        //点击当前索引菜单操作
        clickSubMenuHandler(index,item){
            // console.log('当前菜单索引:'+index) 
            // console.log('当前菜单字母:'+item)
            this.crruentSubMenuIndex = index;
            if(index===0){
                this.tempGamesIconList = this.gameIconList;
            }else{
                this.selectSubGames(item);
            }
        },
        //当前选择的游戏
        clickGameItemHandler(gameItem){
            this.$router.push({name:'productList',params:{'currentSelectGameItem':gameItem}})
        },
        selectMainGames(index){ //0全部  1手游  2端游
            if(index===0){
                this.tempGamesIconList = this.gameIconList;
            }else if(index===1){
                this.tempGamesIconList = this.gameIconList.filter((item)=>{
                    return item.asMobileGame===true;
                })
            }else if(index===2){
                this.tempGamesIconList = this.gameIconList.filter((item)=>{
                    return item.asPcGame===true;
                })
            }
        },
        selectSubGames(letter){
            this.tempGamesIconList = this.gameIconList.filter(game => game.pinyin.charAt(0).toUpperCase() === letter);
        }
    },
    computed:{
        ...mapState({
            gameIconList:state=>state.home.gameIconList
        }),
        sliderStyle(){ //10+20  110--2*(10+20)+50  190--3*(10+20)+100
            let tempWith = this.crruentMainMenuIndex*50 + (this.crruentMainMenuIndex+1)*((70-30)/2+10);
            return tempWith+'px';
        },
        mainMenuStyle(){
            return 'color: #F02233;font-weight: bold;';
        },
        subMenuStyle(){
            let tempStyle = this.crruentSubMenuIndex>0?'transform: scale(1.8);':'';
            return 'color: #F02233;font-weight: bold;'+tempStyle;
        }
    },
    mounted(){
        window.scrollTo(0,0);
        this.tempGamesIconList = this.gameIconList;
    }
}
</script>

<style lang="less" scoped>
.games-select-list{
    margin-top: 20px;
    width: 1200px;
    padding-bottom: 30px;
    background: #FFFFFF;
    border-radius: 6px;
    
    .games-select-main-menu{
        width: 1200px;
        height: 52px;
        background: #FFFFFF;
        box-shadow: 0px 1px 0px 0px rgba(0,0,0,0.03);
        border-radius: 6px 6px 0px 0px;
        ul{
            width: 1200px;
            height: 52px;
            list-style-type: none;
            display: flex;
            position: relative;
            li{
                margin-left: 10px;
                width: 70px;
                height: 52px;
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: default;
                .games-category-menu-title{
                    height: 20px;
                    font-family: MicrosoftYaHei;
                    font-size: 15px;
                    color: #555555;
                    line-height: 20px;
                    text-align: left;
                    font-style: normal;
                }
                &:hover .games-category-menu-title{
                    font-weight: bold;
                    color: #F02233;
                }
                // &:hover .games-category-menu-slider{
                //     display: block;
                // }
            }
            .games-category-menu-slider{
                width: 30px;
                height: 2px;
                background: #F02233;
                position: absolute;
                left: 20px;
                bottom: 0px;
                // display: none;
                transition: all 0.3s ease-in-out;
            }
        }
    }
    .games-select-sub-menu-index{
        width: 1200px;
        height: 25px;
        ul{
            margin-top: 21px;
            width: 1200px;
            list-style-type: none;
            display: flex;
            li{
                list-style: none;
                margin-left: 10px;
                width: 30px;
                
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: default;
                .sub-menu-index{
                    width: 10px;
                    height: 19px;
                    font-family: MicrosoftYaHei;
                    font-size: 14px;
                    color: #666666;
                    line-height: 19px;
                    text-align: left;
                    font-style: normal;
                }
                &:hover {
                    background: #FFF3F4;
                    border-radius: 4px;
                }
                &:hover .sub-menu-index{
                    color: #F02233;
                    font-weight: bold;
                }
                &:first-child{
                    margin-left: 10px;
                    width: 76px;
                    height: 25px;
                    line-height: 25px;
                    border-radius: 4px;
                    display: flex;
                    .sub-menu-index{
                        width: 56px;
                    }
                }

            }
        }
    }
    .games-select-items{
        margin-top: 10px;
        width: 1200px;
        ul{
            width: 1200px;
            min-height: 360px;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            li{
                margin-top: 10px;
                width: 150px;
                height: 150px;
                list-style: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid #FFFFFF;
                cursor: default;
                .game-icon{
                    margin-top: 14px;
                    width: 90px;
                    height: 90px;
                }
                .game-name{
                    margin-top: 14px;
                    height: 19px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                    line-height: 19px;
                    text-align: right;
                    font-style: normal;
                }
                &:hover {
                    border: 1px solid #F02233;
                }
                &:hover .game-icon{
                    transform: scale(1.1);
                    transition: all 0.3s ease-in-out;
                }
                &:hover .game-name{
                    color: #F02233;
                }
            }
            
        }
    }
}

</style>